<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'In đơn hàng'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In đơn hàng
        </b-button>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div id="printMe">
          <div class="print">
            <b-row class="justify-content-md-center">
              <b-col cols="6" align-self="end">
                <div class="header-print">
                  <h5 class="font-weight-bolder">PHIẾU GỬI</h5>
                  <p>Phiếu gửi chỉ có giá trị khi có dấu của bưu cục</p>

                  <div>
                    <div>
                      <p class="font-weight-bolder">({{ orderId }})</p>
                    </div>
                  </div>
                  <p>
                    Phát hàng thu bên đầu nhận
                    <span class="font-weight-bolder"
                      >{{ convertPrice(sumTotalFinal) }} Đ</span
                    >
                  </p>
                </div>
              </b-col>
              <b-col cols="6" align-self="center">
                <!-- <div class="text-right">{{barcode}}</div> -->
              </b-col>
            </b-row>
            <div></div>
            <table class="table no-border" style="width: 100%; border: none">
              <tr>
                <td>
                  <span>THÔNG TIN NGƯỜI GỬI</span>
                  <table class="col-md-8 table-borderless">
                    <tr>
                      <td>Họ và tên</td>
                      <td>
                        <b>{{ dataset.storeName }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Điện thoại</td>
                      <td>{{ dataset.storePhone || 18006018 }}</td>
                    </tr>
                    <tr>
                      <td>Địa chỉ</td>
                      <td>{{ dataset.storeAddress }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <span>THÔNG TIN NGƯỜI NHẬN</span>
                  <table class="col-md-12 table-borderless">
                    <tr>
                      <td>Họ và tên</td>
                      <td>
                        <b>{{ dataset.customerName }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Điện thoại</td>
                      <td>{{ dataset.customerMobile }}</td>
                    </tr>
                    <tr>
                      <td>Địa chỉ</td>
                      <td>
                        {{ getAddressCustomer(dataset) }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td style="width: 65%">
                  <span>THÔNG TIN HÀNG HÓA</span>
                  <b-row class="col-md-12">
                    <table class="table table-bordered table-product">
                      <thead>
                        <tr>
                          <th style="text-center">#</th>
                          <th class="headerTable">Sản phẩm</th>
                          <th class="headerTable">SL</th>
                          <th class="headerTable">Đơn giá</th>
                          <th class="headerTable">Chiết khấu</th>
                          <th class="headerTable">Thành tiền</th>
                        </tr>
                      </thead>

                      <tbody
                        v-for="(item, index) in dataset.orderItems"
                        :key="index"
                      >
                        <tr>
                          <td class="text-center">
                            <p v-if="item.orderItemType === ITEM_TYPE.MAIN">
                              {{ index + 1 }}
                            </p>
                          </td>

                          <td>
                            <div v-if="!htmlIsShow(item, 'isCombo')">
                              <span
                                v-if="item.orderItemType === ITEM_TYPE.GIFT"
                              >
                                <i class="fas fa-gift ml-4 mr-1 text-dark"></i>
                              </span>
                              <span
                                v-if="item.orderItemType === ITEM_TYPE.ATTACH"
                              >
                                <i class="fas fa-tags ml-4 text-dark"></i>
                              </span>
                              <span style="font-weight: 600">
                                {{ item.productName }}
                              </span>

                              <p v-if="htmlIsShow(item, 'haveImei')">
                                <i style="font-size: 12px; color: #464e5f"
                                  >IMEI: {{ item.imeiCode }}</i
                                >
                              </p>
                            </div>
                            <div v-else class="table-responsive">
                              <table class="table no-border">
                                <tr style="display: table-caption">
                                  <p style="font-weight: 600" class="m-0">
                                    {{ item.productName }}
                                  </p>
                                </tr>
                                <tr
                                  v-show="
                                    item.productInCombos &&
                                    item.productInCombos.length
                                  "
                                  v-for="(items, index) in item.productInCombos"
                                  :key="index"
                                  style="border-top: 1px solid gray"
                                >
                                  <td
                                    style="
                                      border: none !important;
                                      width: -webkit-fill-available;
                                    "
                                  >
                                    <i
                                      style="color: rgb(24, 28, 50)"
                                      class="fas fa-cube"
                                    ></i>
                                    {{ items.productName }}
                                    <!-- <i
                                          style="font-size: unset; color: unset"
                                        >
                                          ({{
                                            convertPrice(items.sellingPrice)
                                          }})
                                        </i> -->
                                  </td>
                                  <td style="border: none !important">
                                    {{ items.quantity }}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </td>

                          <td class="text-center">{{ item.quantity }}</td>

                          <td class="text-right">
                            <span v-if="!htmlIsShow(item, 'isItemGift')">
                              {{ convertPrice(item.productPrice) }}
                            </span>
                          </td>

                          <td class="text-right">
                            <span v-if="!htmlIsShow(item, 'isItemGift')">
                              {{ convertPrice(discountByMany(item)) }}</span
                            >
                          </td>

                          <td class="text-right">
                            <span v-if="!htmlIsShow(item, 'isItemGift')">
                              {{ convertPrice(sumAmountItem(item)) }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th style="text-align: right" id="total" colspan="2">
                            Tổng
                          </th>
                          <td></td>
                          <td></td>
                          <td class="text-right font-weight-bolder">
                            {{ convertPrice(sumDiscountItemsAmount) }}
                          </td>
                          <td class="text-right font-weight-bolder">
                            {{ convertPrice(sumTotalAmount) }}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </b-row>
                </td>
                <td>
                  <table class="col-md-12 table-borderless">
                    <tr>
                      <td class="text-center">Tạo vận đơn {{ currentDay }}</td>
                      <td class="text-center">Ngày giờ gửi __/__/__</td>
                    </tr>
                    <tr>
                      <td class="text-center">Người gửi</td>
                      <td class="text-center">Nhân viên chấp nhận</td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr>
                <td>
                  <span>THÔNG TIN CƯỚC PHÍ</span>
                  <div>
                    <table class="col-md-8 table-borderless">
                      <tr>
                        <td style="width: 40%">Phí dịch vụ vận chuyển</td>
                        <td>
                          <span v-if="sumShipFee">
                            {{ convertPrice(sumShipFee) }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Đã thanh toán</td>
                        <td>
                          {{ convertPrice(sumPayment) }}
                        </td>
                      </tr>
                      <tr>
                        <td>Chiết khấu tổng :</td>
                        <td>{{ convertPrice(sumDiscountHeader) }}</td>
                      </tr>
                      <tr>
                        <td>
                          <h4 class="font-weight-bolder">Tổng thu :</h4>
                        </td>
                        <td>
                          <h4 class="font-weight-bolder">
                            {{ convertPrice(sumTotalFinal) }} Đ
                          </h4>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td>
                  <table class="col-md-12 table-borderless">
                    <tr>
                      <td class="text-center">Ngày giờ phát</td>
                      <td class="text-center">Ngày giờ nhận</td>
                    </tr>
                    <tr>
                      <td class="text-center">__/__/__</td>
                      <td class="text-center">__/__/__</td>
                    </tr>
                    <tr>
                      <td class="text-center">Nhân viên phát</td>
                      <td class="text-center">Người nhận</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import _ from 'lodash';
import { ITEM_TYPE, PRODUCT_TYPE } from '@/utils/enum.js';
import {
  makeToastFaile,
  convertPrice,
  calculateDiscountAmount,
  cloneDeep,
} from '@/utils/common.js';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      barcode: 'barcode',
      ITEM_TYPE: ITEM_TYPE,
      PRODUCT_TYPE: PRODUCT_TYPE,
      dataset: {
        id: '',
        storeId: '',
        storeAddress: '',
        status: '',
        customerNote: '',
        checkDate: '',
        checkTime: '',
        codeShip: '',
        discountAmount: '',
        transferAmount: '',
        depositAmount: '',
        paymentDate: '',
        customerShipFee: '',
        shipFee: '',
        orderSourceId: '',
        saleNote: '',
        orderItems: [],
        createdAt: '',
        createdBy: '',
        customerId: '',
        customerName: '',
        customerMobile: '',
        cashAccountName: '',
        transferAccountName: '',
        saleId: '',
        creditAmount: '',
        installedMoneyAmount: 0,
      },
      currentDay: moment(new Date()).format('DD/MM/YYYY'),
      employeeName: 'Trung',
      orderId: null,
    };
  },
  created() {
    const orderId = this.$route.query.id || null;
    if (!orderId) {
      makeToastFaile('Yêu cầu mã id đơn hàng');
    }
    this.getOrderById(orderId);
    this.orderId = orderId;
    // this.printData();
  },
  methods: {
    convertPrice,
    htmlIsShow(item, action) {
      let check = false;
      switch (action) {
        case 'isCombo': {
          check =
            item.productType === PRODUCT_TYPE.PRODUCT_COMBO ? true : false;
          break;
        }
        case 'haveImei': {
          check = item.imeiCode && item.imeiCode.trim().length > 0;
          break;
        }
        case 'isItemGift': {
          check = item.orderItemType === ITEM_TYPE.GIFT ? true : false;
          break;
        }
      }
      return check;
    },
    sumAmountItem(item) {
      const quantity = Number(item.quantity) || 0;
      const productPrice = Number(item.productPrice) || 0;
      const totalAmountItem = quantity * productPrice;
      const discountProducts = this.discountByMany(item, quantity);

      return totalAmountItem - discountProducts;
    },
    discountByMany(item, quantity) {
      const quantityItem = quantity
        ? quantity
        : item.quantity
        ? Number(item.quantity)
        : 0;
      const discountOfOneProduct = this.discountByOne(item);
      return quantityItem * discountOfOneProduct;
    },
    discountByOne(item) {
      const discountAmount = Number(item.discountAmount) || 0;
      const discountType = Number(item.discountType) || 1;
      const quantity = Number(item.quantity) || 0;
      const productPrice = Number(item.productPrice) || 0;

      const discountAProduct = calculateDiscountAmount(
        discountAmount,
        discountType,
        productPrice,
        quantity
      );
      return discountAProduct;
    },
    printData() {
      const prtHtml = document.getElementById('printMe').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
         ${stylesHtml}
         <style>
         .print {
             font-size: 160%;
           }
         </style>
        </head>
        <body>`);
      WinPrint.document.write(`${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, 500);
      // WinPrint.close();
    },
    getDistrictById: async function (cityId, districtId) {
      ApiService.get(`city/${cityId}/district`).then((response) => {
        const { data } = response.data;
        const districtKey = _.keyBy(data, 'id');
        const district = districtKey[districtId];
        this.dataset.customerDistrict = district.name;
      });
    },
    getWardById: async function (districtId, wardId) {
      ApiService.get(`city/${districtId}/ward`).then((response) => {
        const { data } = response.data;
        const wardKey = _.keyBy(data, 'id');
        const ward = wardKey[wardId];
        this.dataset.customerWard = ward.name;
      });
    },
    getOrderById: async function (orderId) {
      await ApiService.query(`orders/${orderId}`).then(async (response) => {
        const { data } = response.data;
        const { status, message } = response.data;
        console.log('data', response.data);
        this.dataset.orderItems = [];
        if (status) {
          data.orderItems = this.getOrderItems(data);
          this.dataset = data;
        } else {
          makeToastFaile(message);
        }

        // this.saleId = data.saleId;

        // this.note = saleNote;
        // this.dateCome = checkDate
        //   ? moment(checkDate).format('DD/MM/YYYY')
        //   : null;
        // this.timeCome = checkTime;
        // this.codeShip = codeShip;
        // this.priceDebit = transferAmount;
        // this.deposit = depositAmount;
        // this.moneyCredit = creditAmount;
        // this.moneyInstallment = installedMoneyAmount;
        // this.datePay = paymentDate
        //   ? moment(paymentDate).format('DD/MM/YYYY')
        //   : null;
        // this.customerShipFee = customerShipFee;
        // this.shipFee = shipFee;
        // this.selectProvider = orderSourceId;
        // this.createdAt = moment(createdAt).format('DD-MM-YYYY');
        // this.createdBy = createdBy;
        // this.selectedAccountant = transferAccountName;
        await Promise.all([
          this.getCustomerInfoById(data.customerId),
          this.getStoreById(data.storeId),
        ]);

        // this.storeId = storeId;
      });
      this.printData();
    },
    getOrderItems(data) {
      const terms = cloneDeep(data.orderItems);
      const mainItems = terms.filter(
        (item) => item.orderItemType === ITEM_TYPE.MAIN
      );
      const childItems = terms.filter(
        (item) => item.orderItemType !== ITEM_TYPE.MAIN
      );
      let orderItems = [];
      mainItems.map((main) => {
        orderItems.push(main);
        const filterdChilds = childItems.filter(
          (child) => child.belongOrderDetailId === main.id
        );
        orderItems = orderItems.concat(filterdChilds);
      });
      return orderItems;
    },
    getCustomerInfoById: function (customerId) {
      ApiService.get(`customer/${customerId}`).then((response) => {
        const { data } = response.data;
        const { status, message } = response.data;
        if (status) {
          const customerName = this.dataset.customerName || data.fullName;
          const customerAddress = this.dataset.customerAddress || data.address;

          this.dataset.customerName = customerName;
          this.dataset.customerAddress = customerAddress;

          if (data.city && data.disctrict) {
            this.getDistrictById(data.city, data.district);
          }

          if (data.district && data.ward) {
            this.getWardById(data.district, data.ward);
          }
        } else {
          makeToastFaile(message);
        }
      });
    },
    getStoreById: function (storeId) {
      ApiService.query(`stores/${storeId}`).then((response) => {
        const { data } = response.data;
        const { status, message } = response.data;
        if (status) {
          this.dataset.storeAddress = data.address;
        } else {
          makeToastFaile(message);
        }
      });
    },
    getAddressCustomer(dataset) {
      const data = {
        customerAddress: dataset.customerAddress,
        customerWard: dataset.customerWard,
        customerDistrict: dataset.customerDistrict,
        customerCity: dataset.customerCity,
      };

      const pickedByData = _.pickBy(data);
      if (_.isEmpty(pickedByData)) return '';
      return Object.values(pickedByData).join(',');
    },
  },
  components: {
    KTCodePreview,
    Loader,
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },
    sumDiscountHeader() {
      const order = this.dataset;

      const discountAmount = order.discountAmount;
      const discountType = order.discountType || 1;

      const totalAmount = this.sumTotalAmount;

      const discountTotalAmount = calculateDiscountAmount(
        discountAmount,
        discountType,
        totalAmount
      );

      return discountTotalAmount;
    },
    sumPayment() {
      const order = this.dataset;
      const installedMoneyAmount = order.installedMoneyAmount || 0;
      const creditAmount = order.creditAmount || 0;
      const transferAmount = order.transferAmount || 0;
      const depositAmount = order.depositAmount || 0;
      const sumPayment =
        installedMoneyAmount + creditAmount + transferAmount + depositAmount;

      return sumPayment;
    },
    sumShipFee() {
      const order = this.dataset;
      const customerShipFee = Number(order.customerShipFee) || 0;
      const shipFee = Number(order.shipFee) || 0;
      return customerShipFee + shipFee;
    },
    sumDiscountItemsAmount() {
      const orderItems = this.dataset.orderItems || [];

      const distcountAmountItems = orderItems.reduce((result, current) => {
        const discount = this.discountByMany(current);
        return result + discount;
      }, 0);
      return distcountAmountItems;
    },
    sumDiscountAmount() {
      const distcountAmountItems = this.sumDiscountItemsAmount;

      const discountTotalAmount = this.sumDiscountHeader;

      return discountTotalAmount + distcountAmountItems;
    },
    sumTotalFinal() {
      const sumDiscountHeader = this.sumDiscountHeader;
      const totalAmount = this.sumTotalAmount;
      const shipFee = this.sumShipFee;
      return totalAmount + shipFee - sumDiscountHeader;
    },
    sumTotalAmount() {
      const orderItems = this.dataset.orderItems || [];

      const sumOrderItems = orderItems.reduce((result, item) => {
        if (item.orderItemType !== ITEM_TYPE.GIFT) {
          const sumAmountItem = this.sumAmountItem(item);
          result += sumAmountItem;
        }
        return result;
      }, 0);
      return sumOrderItems;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Đơn hàng', route: '/orders' },
      { title: 'In đơn hàng' },
    ]);
  },
};
</script>

<style scoped>
.header-print {
  text-align: center;
  width: 100%;
}
.font-size-print {
  font-size: 13px;
}
.font-size-name {
  font-size: 13px;
}
.container-header {
  display: flex;
}
.container-header > div:first-child {
  margin-right: auto;
  visibility: hidden;
}
.container-header > div:last-child {
  margin-left: auto;
}
</style>